/* primary colors: #fff, button border color: #9e9e9e45(grey), #007ebd(blue)*/
.App {
  width: 100%;
  margin: auto;
  --bs-gutter-x:0;
}

.row{
  --bs-gutter-x:0;
}


.marginbt{
  margin: 0 auto 4rem !important;
}

.btnfocus{
  background-color: #007ebd;
  border-color: #007ebd !important;
  color: white !important;
}

.side-nav{
  display: flex;
  width: 99%;
  justify-content: flex-end;
}
.searchbar{
  display: flex;
  color: #323232;
  align-items: center;
}

.infobar{
  display: flex;
  color: #323232;
  align-items: center;
  list-style: none;
  margin-bottom: 0;
}

.blue-icon{
  color:#007ebd ;
}

.infobar li{
  padding-right: 1rem;
}

.searchbar input{
  margin-right: 0.5rem;
  border-radius: 50px;
  border: 1px solid #323232;
  padding-left:1rem ;
}

.pageTitle{
  z-index: 999;
    position: relative;
    color: white;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 3rem;
    letter-spacing: 0.3rem;
}

a {
  text-decoration: none;
  color: black;
}
.header{
  background-color: grey;

}

.header-contents{
  margin: 15rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
}



.active>.page-link{
  background-color: #007ebd;
    border-color: #007ebd;
}

.page-link{
  color:#007ebd;
}

@keyframes animate{
0%{
  opacity: 0;
  transform: rotate(45deg) translate(-20px, -20px);
}
50%{
  opacity: 1;
} 
100%{
  opacity: 0;
  transform: rotate(45deg) translate(20px, 20px);
} 
}

.require{
color: red;
}

.contactSwiper{
width: 375px;
height: 600px;
}

.services-swiper{
  width: 100%;
}

.swiper-container{
  width: 100%;
}
.navbar{
  background-color:#fff;
  justify-content: center;
  max-height: 115px;
  display: flex;
  flex-direction: column;
}

.main-nav,.aboutusSection,.bannerContent, .formContainer, .servicePg-contents,.services{
  max-width: 1500px;
}

.main-nav{
  display: grid;
  grid-template-columns: 1fr 70%;
  align-items: center;
  width: 90%;
  background-color: white;
  margin: 0 1rem;
  justify-items: center;
}
.header-slides{
  padding: 24rem;
  background-repeat: no-repeat;
  z-index: -1;
}
.videoPlay{
  position: absolute;
  top: 40%;
  right: 45%;
  background-color: white;
  border-radius: 50px;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.eventDecorBtns{
  display: flex;
  width: 80%;
  margin: 0 auto 3rem;
  max-width: 1200px;
}



.eventDecorBtns a{
  width: 50%;
}

.eventDecorBtns a .eventDecorBtn{
  width: 80%;
}

.videoPlay i{
font-size: 3rem;
color: #007ebd;
}

.swiper-slide{
  overflow: hidden;
}

.swiperGallery-image{
  width: 100%;
}

.servicespg-gallery{
   width: 80%; 
   max-width: 1200px;
  margin: 5rem auto 3rem
}

.swipperGallery-container{
  width: 80%;
  margin: auto;
  max-width: 1200px;
}

.swipperGallery-container p{
  background-color: #f5f5f5;
  padding: 2rem 1rem;
  color: #818181;
}


.slide1{
  background:  center no-repeat url(../images/mikey-front-slider-2-alt.jpg);
}

.slide2{
  background: linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ) , center no-repeat url(../images/home-landing-transition5.jpg);
  
}

.slide3{
  background: linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ) , center no-repeat url(../images/home-landing-transition2.jpg);
  
}
.slide4{
  background: linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ) , center no-repeat url(../images/home-landing-transition4-1.jpg);
}
.slide5{
  background: linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ) , center no-repeat url(../images/home-landing-transition6-edit.jpg);
}

.navbar-left{
  display: flex;
  justify-self: left;
}

.navbar-right{
  justify-self: end;
  display: flex;
}
.navbarContainer{
height: 110px;
}

.navbar-brand{
  justify-self: flex-start;
}

.navBtns{
  justify-self: flex-end;
  margin-right: 1.5rem;
}

.navBtns button, .navBtns li{
  cursor: pointer;
}

.contactBtn{
  padding: 0.5rem 2rem;
  border-radius: 10px;
  border: 1px solid #007ebd;
  background-color: transparent;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  color: #323232;
  font-size: 1rem;
  font-weight: 600;
  margin-left: 2rem;
}


form .btn{
  padding: 0.5rem 1.5rem;
  border-radius: 10px;
  border: 1px solid #9e9e9e45;
  background-color: transparent;
  letter-spacing: 0.2rem;
  line-height: 1.5rem;
  color: black;
  font-size: 0.8rem;
  font-weight: 500;
}



.navBtn:hover{
  border-bottom: 1px solid #007ebd;
  border-radius: 0;
  background-color: transparent;
  color: #007ebd;
}

.contactBtn:hover, form .btn:hover{
  border: 1px solid #007ebd;
  background-color: #007ebd;
  color: white;
  font-family: 'Cabin', sans-serif;
}

.swiper-wrapper{
  top:24px
}

.swiperGallery-wrapper{
  margin-bottom: 5rem;
}
.slogan{
  position: absolute;
  color: #fff;
  z-index: 9999;
  letter-spacing: 0.2rem;
  top: 10%;
  left: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

}

@keyframes bombIn {
  0%{
    transform: scale(0);
  }
  70%{
    transform: scale(1);
  }
  80%{
    transform: scale(0.8);
  }
  100%{
    transform: scale(1);
  }
}

.longdash{
  width: 5rem;
  height: 1px;
  background-color: white;
  display: inline-block;
  margin-bottom: 0.5rem;
  margin-right: 1rem;
  animation: text-ease-in 0.5s ease-in 1 alternate;
}

.subheader{
  font-weight: 500;
  font-size: 1.5rem;
  text-transform: uppercase;
  width: 90%;
  animation: bounce 1s ease-in 1 alternate;
}


.servicePg-contents{
  width: 85%;
  margin: auto;
}

.dropdownArrowContainer{
  width: 100%;
    position: absolute;
    top: -8px;
    left: -57px;
    display: flex;
    justify-content: center;
}
@keyframes text-ease-in{
from {
  transform: translateX(100%);
}
to{
  transform: translateX(0%);
}
}

@keyframes slicing {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0%);
  }
}

.slogan h2{
  text-transform: uppercase;
  width: 80%;
  font-size: 130px;
  font-weight: 600;
  margin-bottom: 1rem;
  width: 1270px;
  animation: bombIn 1s ease-in-out 1 both;
  word-break: break-word;
  line-height: 8rem;
}

.slogan button{
  background-color: #fff;
  color: #007ebd;
  transition: all 0.4s linear 0s;
  border: none;
  border-radius: 30px;
  font-weight: 700;
  letter-spacing: 0.2em;
  line-height: 1;
  padding: 24px 45px;
  text-transform: uppercase;
  font-size: 1.5rem;
  max-width: 70%;
  margin-top: 1rem;
  animation: bigbounce 1.5s ease-in 1 alternate;
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);} 
  40% {transform: translateY(-30px);} 
  60% {transform: translateY(-15px);} 
} 

@keyframes bigbounce {
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);} 
  40% {transform: translateY(-150px);} 
  60% {transform: translateY(-30px);} 
  90% {transform: translateY(-15px);} 
} 


.slideImg{
  filter: brightness(0.4);
  width: 100%;
}

.navbar-logo{
  width: 17rem;
}

.navBtn{
  background-color: transparent;
  border: none;
  color: #323232;
  font-weight: 500;
  letter-spacing: 1.5px;
  font-size: 14px;
  padding-right: 0;
  padding-left: 0;
}


.navBtn span{
  text-transform: uppercase;
}
.aboutusSection{
  margin: 4rem auto;
  text-align: center;
}

.aboutUs div,.formContainer .contactInfo, .formContainer form{
  width: 50%;
}

.contactInfo{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.footerSection{
  background-color: #323232;
  color: #b1b1b1;
  display: flex;
  padding: 2rem;
  margin-top: 2rem;
}

.disclaimerSection{
  width: 100%;
  margin:auto;
  padding: 1rem;
  text-align: center;
  color: #818181;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  background-color: #fff;
}

.footerSection h4, .footer-icons a{
  color: white;
}

.footer-contact i{
  color: #007ebd;
  margin-right: 3px;
}

.searchSection{
  display: flex;
  flex-direction: column;
  position: relative;
}

.suggestions{
  position: absolute;
    background-color: rgba(255, 255, 255, 0.9);
    top: 29px;
    z-index: 1000;
    list-style: none;
    padding: 0.5rem 0.5rem 1rem;
}

.suggestions li{
  font-weight: 600;
  padding: 0.5rem;
  border-bottom: 1.5px solid  #b1b1b1;
  text-transform: capitalize;
  color:#323232;
}

.suggestions li:hover, .suggestions a:hover{
  color:#007ebd
}

.footer-contact ul li a{
  color: #b1b1b1;
}

.footer-icons{
  font-size: 1.5rem;
}

.footer-contact ul {
  padding-left: 0rem;
}

.footerSection h4{
 margin-bottom: 1rem;
 font-size: 1.8rem;
}

.footer-left, .footer-right{
  width: 23%;
  margin: auto;
  text-align: center;
  
}

.footer-right{
  text-align: center;
}
.footerSection p, .footerSection li{
  font-size: 14px;
  font-weight: 500;
  margin-top: 1rem;
}
.footer-right li{
  list-style: none;
}



h3{
  font-size: 2rem;
  font-weight: 800;
  
}

h3.provideservice{
  font-size: 2rem;
  color: #007ebd;
  margin-bottom: 30px;
}

@media all and (max-width: 1000px)  {
  h3.provideservice{
    font-size: 1.5rem;
  }
}

.services{
  width: 85%;
  margin: auto;
  display: flex;
  margin-bottom: 4rem;
}

.service{
border: none;
margin-bottom: 3rem;
padding: 1rem;
}

.card-text{
font-weight: 500;
}

.card-body{
  text-align: center;
}

.card-title{
font-weight: 800;
font-size: 1.8rem;
}

.serviceImg{
  width: 50%;
  padding-left: 1rem;
}


.logoImg{
width: 70%;
}

.tardis, .infuso{
  width: 50%;
}

.banner{
  background-color: black;
  color: #fff;
}

.bannerContent{
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 7rem auto;
}

.nav-link{
  display: inline-block;
  align-self: center;
  justify-self: flex-end;
}

.bannerBtnContainer{
  align-self: center;
  margin-right: 5rem;
}

.bannerBtn{
background-color: transparent;
border: 1px solid #BBBBBB;
    margin-right: 10rem;
    padding: 1.5rem 0;
    border-radius: 0;
    width: 100%;
}

.bannerBtn:hover{
  border: 1px solid #007ebd;
  background-color: transparent;
  color: white;
  font-weight: 500;
  transform: scale(1.2);
}

.formContainer, .aboutUs{
  display: flex;
  justify-content: space-between;
}

.formContainer, .aboutUs{
  width: 80%;
  margin:3rem auto;
}

.lfContent h2{
  font-family: 'Roboto', sans-serif;
  font-size: 8rem;
}
.lfContent h2 span{
  color: black;
    -webkit-text-stroke-color: #BBBBBB;
    -webkit-text-stroke-width: thin;
}


.smallText{
font-size: 1.7rem;
}

.xsmallText{
  font-size: 1.2rem;
}

.xxsmallText{
  font-size: 0.8rem;
}

.smallBold{
  font-weight: 700;
  line-height: 2.5rem;
  color: #545457;
}

.address {
  margin-bottom: 20px;
}

.address span {
  font-size: 2rem;
  color: #007ebd;
}

.contactInfo h5{
  margin-top: 1rem;
  font-weight: 800;
  font-size: 1.7rem;
  margin-bottom: 3rem;
}

.contactInfo h5 span{
  color: #01C3FF;
}

form{
  margin-right: 5rem;
}

.rtSection span {
  font-weight: 800;
}

.rtSection p{
  margin: 4rem 0;
  font-weight: 500;
}

form{
  display: flex;
    flex-direction: column;
    margin: 0;
    align-items: center;
}

form h3, .contactInfo h3{
  margin-top: 3rem;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
}

.mb-3, form .btn{
  width: 70%;
}

.form-control{
  line-height: 3rem;
  margin-bottom: 1rem;
}

.modal{
  max-width: 2000px;
  overflow: scroll;
  --bs-modal-width: 100%;
}

.imageWizVideos,.swiper-video{
position: relative;
top: 24px;
background-color: #f5f5f5;
}

.modal-content{
background-color: transparent;
border: none;
border-radius: none;
top: 8rem;
right: 2rem;
}

.portfolio_swiper .swiper-button-prev, .portfolio_swiper .swiper-button-next{
  color:white;
}

.portfolio_swiper .swiper-slide{
  right: -20%;
}

.portfolio_swiper{
  width:80%;
}

.scrollUpBtn{
  width: 3rem;
  height: 3rem;
  font-size: 2rem;
  border-radius: 50%;
  align-items: center;
  transform: rotate(90deg);
  justify-content: center;
  background-color: #007ebd;
  border: none;
  left: 1%;
  display: flex;
}

.eventDecorBtn{
  width: 53%;
  margin: auto;
  box-shadow: 4px 8px 16px 0 rgb(0 0 0 / 30%);
}

.eventDecorBtn img{
  width: 100%;
}
.eventDecorBtn:hover{
  transform: scale(1.05);

}

form .btn{
  margin-bottom: 1rem;
}
@media all and (max-width:1450px) {
  .swiper-video{
    height: 570px !important;
  }
}
@media all and (max-width:1400px) {
  .header-contents{
    margin: 10rem auto;

  }

.slogan{
  left: 0%;
  top: 10%;
} 

.slogan h2{
  font-size: 120px;
}

.card-text, .aboutUs p{
  font-size: 0.8rem;
} 

.aboutUs h3{
  font-size: 1.8rem;
}
}

@media all and (max-width:1250px) {
  .swiper-video{
    height: 500px !important;
  }
}
@media all and (max-width: 1200px)  {
  .slogan h2{
  font-size: 110px;
  width: 1100px
}
}

@media all and (max-width: 1100px)  {

  .slogan h2{
  font-size: 100px;
  width: 1000px
}
}

@media all and (max-width: 1000px)  {
  .swiper-video{
    height: 390px !important;
  }
  .slogan h2{
  font-size: 90px;
  width: 900px;
  line-height: 5rem;
}
}

@media all and (max-width: 900px)  {
  .slogan h2{
  font-size: 75px;
  max-width: 700px
}
}

@media all and (max-width: 750px)  {
  .swiper-video{
    height: 300px !important;
  }
}
@media all and (max-width: 600px)  {
  .eventDecorBtns{
    flex-direction: column;
  }

  .eventDecorBtns a{
    width: 100%;
    margin-bottom: 1rem;
  }
  .stf__parent{
    max-width: 400px;
  }
  .stf__parent canvas{
    width: 400px !important;
    height: auto !important;
    left: -13px !important;
  }
  .swiper-video{
    height: 230px !important;
  }
  .searchSection{
    margin: 1rem 0 1rem 1rem;
  }
  .videoPlay{
    width: 50px;
    height: 50px;
  }

  .videoPlay i{
    font-size: 2rem;
  }
  .header-contents{
    margin: 4rem auto !important;
    padding: 0;

  }

  .swipperGallery-container{
    width: 90%;
  }
  .swipperGallery-container p {
    font-size: 0.9rem;
  }

  .modal_singleImg{
    width: 100%;
  }

  .modal-content{
    top: 13rem;
    right: 0rem;
  }

  .portfolio_swiper{
    width:100%;
  }

  .portfolio_swiper img{
    width: 100%;
  }

  .portfolio_swiper .swiper-slide {
    left: 114%;
    margin-bottom: 2rem;
  }
  .arrowContainer span{
    width: 15px;
    height: 15px;
  }

  .bg-overlay{
    height: 47%;
  }

  .pageTitle{
    font-size: 1.5rem;
    margin-left: 2rem;
  }

.bannerContent{
    width: 80%;
  }

  .navbar-toggler{
    width: 20%;
    padding: 10px !important;
  }

  .navbar-toggler-icon{
    width: 1.1rem;
    height: 1.1rem;
    
  }
  .navBtns{
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: white;
    padding: 1rem 2rem;
    border-radius: 10px;
    top: 6rem;
    left: 0rem;
    width: 100%;
  }

  .navBtn{
    line-height: 1rem;
    font-size: 0.7rem;
    margin-right: 0;
    margin-bottom: 1rem;
    letter-spacing: 0.1rem;
    border: none;
    padding-bottom: rem;
    border-bottom:1px solid #007ebd;
    border-radius: 0;
  }

  .page{
    justify-content: center;
  }

  .navbar-logo{
    width: 9rem;
  }

  .navBtn img{
    margin-left: 0;
  }

  .slogan{
    top: 8rem;
    left: -10px;
    letter-spacing: 0.1rem;
  }

  .slogan h2{
    font-size: 3.5rem;
    width: 100%;
    max-width: 400px;
    line-height: 3.5rem;
  }

  .slogan button{
    font-size: 1rem;
    padding: 24px 22px;
  }

  .subheader{
    font-size: 0.7rem;
  }

  .services, .aboutUs,.formContainer, .banner{
    display: flex;
    flex-direction: column;
  }

  .aboutUs{
    margin-top: 2rem;
  }

  .aboutUs div, .banner div, .service, .card-text, .lfSection h3, .rtSection p, .formContainer .contactInfo,  .formContainer form{
    width: 100%;
  }

  .aboutUs span{
    font-size: 1rem;
  }
  
  .rtSection p{
    margin: 2rem 0;
  }

  .services{
    margin-bottom: 0;
  }

  .banner{
    padding: 2rem;
  }
  
  .lfContent h2{
    font-size: 3rem;
    margin-bottom: 2rem;
    margin-top: 1rem;
  }

  .bannerBtnContainer{
    margin-right: 0;
  }

  .bannerBtn{
    margin-right: 0;
    margin-top: 6rem;
    margin-bottom: 1rem;
    padding: 1rem 2rem;
    font-size: 1rem;
  }

  .banner .nav-link{
    align-self: flex-start;
  }

  .smallText{
    font-size: 1.5rem;
  }

  .contactInfo{
    margin-top: 2rem;
  }

  .mb-3, .formTitleContainer, form .btn{
    width: 100%;
  }

  .logoCenter, .mikeyEvents{
    padding:0
  }

  .logoImg{
    width: 100%;
    margin-bottom: 2rem;
  }

  .tardis, .infuso{
    width: 80%;
  }

  .contactBtn{
    margin-left: 0;
  }
  }

  @media all and (max-width: 500px)  {
    .disclaimerSection{
      font-size: 0.6rem;
    }
    .footer-left, .footer-right{
      width: 80%;
      margin-top: 2rem;
    }
    .footerSection{
      flex-direction: column;
    }
    .header-contents{
      margin: 2.8rem auto !important;
    }

    .main-nav{
      grid-template-columns: 55% 1fr;
      margin-top: 1rem;
    }
    
  }

  @media all and (max-width: 450px)  {
    .swiper-video{
      height: 190px !important;
    }

  }
  @media all and (max-width:800px) {
    .header-contents{
      margin: 7rem auto;
  
    }
  
  }

  @media all and (max-width:1300px) and (min-width:601px){
    .side-nav{
      width: 80%;
    }
    .navbar-right {
      justify-self: center;
    }
    .footer-left, .footer-right{
      width: 35%;
    }
      .swipperGallery-container{
        width: 90%;
      }

    .videoPlay{
      width: 80px;
      height: 80px;
    }

    .videoPlay i{
      font-size: 2.5rem;
    }

    .header-contents{
      padding: 0;
    }

    .card-title{
      font-size: 1.5rem;
    }

    .modal_singleImg{
      width: 60%;
    }

    .bg-overlay{
      height: 65%;
    }

    .navbar-toggler{
      width: 20%;
      padding: 10px !important;
    }
  
    .navbar-toggler-icon{
      width: 1.1rem;
      height: 1.1rem;
      
    }

    .navBtns{
      display: flex;
      flex-direction: row;
      position: absolute;
    top: 100px;
    background-color: white;
    width: 100%;
    right: 0px;
    justify-content: center;
    margin-right: 0rem;
    }

    .contactBtn{
      margin-bottom: 0.5rem;
    }

    .navBtn{
      line-height: 1rem;
      font-size: 0.7rem;
      margin-left: 0.5rem;
    }

    .main-nav{
      width: 90%;
      padding: 0 0.5rem;
      margin: 0;
      grid-template-columns: 70% 1fr;

    }
    
    .mb-3, form .btn, .formTitleContainer{
      width: 100%;
    }

    .tardis, .infuso{
      width: 80% !important;
      margin-bottom: 2rem;
    }

    .logoImg{
      width: 100%;
    }
  
    .subheader{
      font-size: 1rem;
    }
  
    .navbar-logo{
      width:14rem;
  
    }
    .navBtn{
      margin-right: 0rem;
    }
  
    
  .services{
      flex-direction: row;
      padding: 0 1rem;
      width: 100%;
  }
  
  .formContainer form{
    padding-left: 2rem;
  }
  
  .service{
    width: 100%;
  }
  
  .serviceImg{
    width: 80%;
  }
  
  .card-text{
    width: 100%;
  }
  
  .lfContent h2{
    font-size: 3rem;
  }
  
  .formContainer, .aboutUs, .bannerContent{
    width: 90%;
    margin: 5rem auto;
    padding: 2rem 2rem;
  }
  
  .bannerBtnContainer{
    margin-right: 0rem;
  }
  
  .aboutUs h3, .aboutUs p{
    width: 100%;
  }

  .portfolio_swiper .swiper-slide{
    right: -8rem;
  }

  .modal-content{
    top: 8rem;
    right: 0rem;
  }
  
  }

  .brochure{
    background-color: grey;
    height: 100%;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
  }

  .stf__wrapper{
    padding-bottom: 70% !important;
  }

  .stf__parent{
    max-width: 1200px;
    width: 80%;
    margin: 2rem auto;
  }
