.eventHeader{
    background-image: url(../images/top-header.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    font-family: 'Snell Roundhand', sans-serif;                            
    padding: 0.5rem;
    font-size: 6rem;
    text-align: center;
    margin-bottom: 2rem;
    width: 100%;
                                                
}

.eventSubheader{
    font-family: 'Apple Chancery', sans-serif;
    color:#007ebd;     
    text-align: center;   
    font-size: 3rem;
    margin: auto;
    width: 90%;                             
}


.services_header .pageTitle{
    color:#007ebd;
    text-transform: uppercase;
}

.services_header .pageTitle:hover{
    color:#fff;
}

.opacityToggle{
    opacity: 0;
}
.fadeInUp{
    animation-name:  fadeInUp;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: both
}


.images div{
    opacity: 0;
} 

[data-aos="fadeInUp"] {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
}

[data-aos="fadeInUp"] .aos-animate {
      opacity: 1;
      transform: translate3d(0, 0, 0);
}

.seeMorebanner{
    background-color: #444;
    font-size: 1.5rem;
    padding: 1rem;
    text-align: center;
}

.seeMorebanner a{
    color: #fff;
}

@media all and (max-width:800px) {  
    .eventSubheader{
        font-size: 2rem;
    }
}
@media all and (max-width:450px) {
    .arrow{
        width: 1%;
    }

    .eventHeader{
        font-size: 4rem;
    }

}
@keyframes fadeInUp {
    from {
		opacity: 0;
		-webkit-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}